<template>
  <el-main>
    <el-button @click="$router.push('/extension/crossWebsite/addKeyword')" type="primary" class="mb20" size="small">+ 添加关键词</el-button>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="关键词：">
        <el-input size="small" placeholder="请输入关键词" v-model="searchForm.keyword"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="keyword" label="关键词" align="center"></el-table-column>
      <el-table-column prop="store_name" label="关联店铺" align="center"></el-table-column>
      <el-table-column prop="goods_name" label="关联商品" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="$router.push('/extension/crossWebsite/editKeyword?id=' + row.id)">编辑</el-button>
          <el-button type="text" @click="deleteKeyword(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        keyword: '',
      },
      dataList: [],
      total: 0,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        keyword: '',
      };
      this.getDataList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getDataList();
    },
    // 获取关键词列表
    getDataList(style) {
      if (style) this.searchForm.page = 1;
      let obj = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
      };
      if (this.searchForm.keyword) obj.keyword = this.searchForm.keyword;
      this.$axios.post(this.$api.Tikmall.keywordList, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 点击删除关键词
    deleteKeyword(data) {
      this.$confirm('此操作将永久删除该关键词, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.Tikmall.delKeyword, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .mb20 {
    margin-bottom: 20px;
  }
}
</style>
